<template>
  <Cover :active-path="activePath" :show-logo="true">
    <template v-slot:content>
      <div class="mx-auto my-auto">
        <h1 class="display-6 mb-4">{{pageTitle}}</h1>
        <div class="d-flex mt-2 justify-content-center">
          <slot name="page-subtitle"></slot>
        </div>
      </div>
    </template>
  </Cover>
</template>

<script>
import Cover from './Cover.vue';

export default {
  name: 'PageCover',
  components: {
    Cover,
  },
  props: {
    pageTitle: {
      type: String,
      default: () => '',
    },
    activePath: {
      type: String,
      default: () => 'home',
    },
  },
};
</script>

<style scoped>
</style>
