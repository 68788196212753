<template>
  <PageCover :active-path="'album'"
             image="/img/gallery/laser-maze-2.webp"
             page-title="ALBUM">
  </PageCover>
  <ul class="nav justify-content-center mt-5">
    <li
      v-for="(item, index) in sections"
      :key="index"
      class="nav-item">
      <button
        @click="selectedSection = item.id"
        class="nav-link btn mx-2 photo-section"
        :class="selectedSection === item.id ? 'btn-blue' : 'btn-link'" >
        <strong>
          {{item.name}}
        </strong>
      </button>
    </li>
  </ul>
  <div class="mb-5 pb-5 mt-5">
    <div class="images" v-viewer="viewerOptions">
      <img v-for="src in photos"
           width="500"
           height="333"
           class="photo img-fluid"
           :key="'img/gallery/2024/thumbs/' + src"
           :data-source="'img/gallery/2024/' + src"
           :src="'img/gallery/2024/thumbs/' + src">
    </div>
  </div>
  <Footer></Footer>
</template>

<script>
import VueViewer from 'v-viewer';
import Footer from '../components/Footer.vue';
import PageCover from '../components/PageCover.vue';

window.app.use(VueViewer);

export default {
  name: 'Album',
  components: {
    PageCover,
    Footer,
  },
  mounted() {
    if (this.$route.params.section) {
      this.selectedSection = this.$route.params.section;
    }
  },
  data() {
    return {
      viewerOptions: {
        inline: false,
        button: true,
        navbar: true,
        title: false,
        toolbar: true,
        tooltip: false,
        movable: false,
        zoomable: false,
        rotatable: false,
        scalable: false,
        transition: true,
        fullscreen: false,
        keyboard: false,
        url: 'data-source',
      },
      selectedSection: 'all',
      sections: [
        {
          name: 'Prezentare',
          id: 'all',
        },
        {
          name: 'Laser tag',
          id: 'laser-tag',
        },
        {
          name: 'Petreceri',
          id: 'party',
        },
        {
          name: 'Karaoke room',
          id: 'karaoke',
        },
        {
          name: 'Laser maze',
          id: 'laser-maze',
        },
        {
          name: 'Racing simulator',
          id: 'racing',
        },
        {
          name: 'VR',
          id: 'virtual-reality',
        },
        {
          name: 'Retro games',
          id: 'retro-games',
        },
      ],
      initialPhotos: [
        // 'racing-sim.webp',
        // 'birthday-party.webp',
        // 'bunker-arena-5.webp',
        // 'bunker-arena-2.webp',
        // 'bunker-arena-3.webp',
        // 'bunker-arena-4.webp',
        // 'bunker-arena-6.webp',
        // 'bunker-arena.webp',
        // 'karaoke-singing.webp',
        // 'karaoke-2.webp',
        // 'karaoke-behringer.webp',
        // 'karaoke-mic.webp',
        // 'karaoke-mics.webp',
        // 'karaoke-mixer.webp',
        // 'karaoke-overview-2.webp',
        // 'karaoke-overview.webp',
        // 'karaoke-singing-2.webp',
        // 'karaoke-singing-3.webp',
        // 'karaoke.webp',
        // 'laser-maze-entrance.webp',
        // 'laser-maze-2.webp',
        // 'laser-maze-3.webp',
        // 'laser-maze.webp',
        // 'laser-tag-1.webp',
        // 'laser-tag-2.webp',
        // 'laser-tag-3.webp',
        // 'laser-tag-4.webp',
        // 'laser-tag-5.webp',
        // 'laser-tag-6.webp',
        // 'laser-tag-7.webp',
        // 'laser-tag-8.webp',
        // 'laser-tag-9.webp',
        // 'laser-tag-10.webp',
        // 'laser-tag-11.webp',
        // 'laser-tag-12.webp',
        // 'nintendo-switch.webp',
        // 'playstation-2.webp',
        // 'playstation-3.webp',
        // 'playstation.webp',
        // 'racing-sim-2.webp',
        // 'racing-sim-3.webp',
        // 'racing-sim-4.webp',
        // 'racing-sim-5.webp',
        // 'racing-sim-6.webp',
        // 'party_s_1.webp',
        // 'party_s_2.webp',
        // 'party_s_3.webp',
        // 'party_s_4.webp',
        // 'party_s_6.webp',
        // 'party_s_7.webp',
        // 'party_s_8.webp',
        // 'party_s_9.webp',
        // 'party_s_11.webp',
        // 'party_s_12.webp',
        // 'party_s_10.webp',
        // 'party_s_5.webp',
        // 'party_s_13.webp',
        // 'party_s_14.webp',
        // 'party_s_15.webp',
        // 'party_s_16.webp',
        // 'party_s_17.webp',
        // 'party_s_18.webp',
        '2024-bunkerarena-karaoke1.webp',
        '2024-bunkerarena-karaoke2.webp',
        '2024-bunkerarena-karaoke3.webp',
        '2024-bunkerarena-karaoke4.webp',
        '2024-bunkerarena-laser-maze1.webp',
        '2024-bunkerarena-laser-maze2.webp',
        '2024-bunkerarena-laser-maze3.webp',
        '2024-bunkerarena-laser-maze4.webp',
        '2024-bunkerarena-laser-maze5.webp',
        '2024-bunkerarena-laser-maze6.webp',
        '2024-bunkerarena-laser-maze7.webp',
        '2024-bunkerarena-laser-maze8.webp',
        '2024-bunkerarena-laser-maze9.webp',
        '2024-bunkerarena-laser-maze10.webp',
        '2024-bunkerarena-laser-maze11.webp',
        '2024-bunkerarena-laser-maze12.webp',
        '2024-bunkerarena-laser-maze13.webp',
        '2024-bunkerarena-laser-tag1.webp',
        '2024-bunkerarena-laser-tag2.webp',
        '2024-bunkerarena-laser-tag3.webp',
        '2024-bunkerarena-laser-tag4.webp',
        '2024-bunkerarena-laser-tag5.webp',
        '2024-bunkerarena-laser-tag6.webp',
        '2024-bunkerarena-laser-tag7.webp',
        '2024-bunkerarena-laser-tag8.webp',
        '2024-bunkerarena-laser-tag9.webp',
        '2024-bunkerarena-laser-tag10.webp',
        '2024-bunkerarena-laser-tag11.webp',
        '2024-bunkerarena-laser-tag12.webp',
        '2024-bunkerarena-laser-tag13.webp',
        '2024-bunkerarena-laser-tag14.webp',
        '2024-bunkerarena-laser-tag15.webp',
        '2024-bunkerarena-laser-tag16.webp',
        '2024-bunkerarena-laser-tag17.webp',
        '2024-bunkerarena-laser-tag18.webp',
        '2024-bunkerarena-laser-tag19.webp',
        '2024-bunkerarena-laser-tag20.webp',
        '2024-bunkerarena-laser-tag21.webp',
        '2024-bunkerarena-prezentare1.webp',
        '2024-bunkerarena-prezentare2.webp',
        '2024-bunkerarena-prezentare3.webp',
        '2024-bunkerarena-prezentare4.webp',
        '2024-bunkerarena-prezentare5.webp',
        '2024-bunkerarena-prezentare6.webp',
        '2024-bunkerarena-prezentare7.webp',
        '2024-bunkerarena-prezentare8.webp',
        '2024-bunkerarena-prezentare9.webp',
        '2024-bunkerarena-prezentare10.webp',
        '2024-bunkerarena-prezentare11.webp',
        '2024-bunkerarena-prezentare12.webp',
        '2024-bunkerarena-prezentare13.webp',
        '2024-bunkerarena-prezentare14.webp',
        '2024-bunkerarena-prezentare15.webp',
        '2024-bunkerarena-prezentare16.webp',
        '2024-bunkerarena-prezentare17.webp',
        '2024-bunkerarena-prezentare18.webp',
        '2024-bunkerarena-prezentare19.webp',
        '2024-bunkerarena-prezentare20.webp',
        '2024-bunkerarena-prezentare21.webp',
        '2024-bunkerarena-prezentare22.webp',
        '2024-bunkerarena-prezentare23.webp',
        '2024-bunkerarena-prezentare24.webp',
        '2024-bunkerarena-prezentare25.webp',
        '2024-bunkerarena-prezentare26.webp',
        '2024-bunkerarena-prezentare27.webp',
        '2024-bunkerarena-prezentare28.webp',
        '2024-bunkerarena-prezentare29.webp',
        '2024-bunkerarena-prezentare30.webp',
        '2024-bunkerarena-prezentare31.webp',
        '2024-bunkerarena-prezentare32.webp',
        '2024-bunkerarena-prezentare33.webp',
        '2024-bunkerarena-prezentare34.webp',
        '2024-bunkerarena-prezentare35.webp',
        '2024-bunkerarena-prezentare36.webp',
        '2024-bunkerarena-prezentare37.webp',
        '2024-bunkerarena-prezentare38.webp',
        '2024-bunkerarena-prezentare39.webp',
        '2024-bunkerarena-prezentare40.webp',
        '2024-bunkerarena-party1.webp',
        '2024-bunkerarena-party2.webp',
        '2024-bunkerarena-party3.webp',
        '2024-bunkerarena-party4.webp',
        '2024-bunkerarena-prezentare45.webp',
        '2024-bunkerarena-prezentare46.webp',
        '2024-bunkerarena-prezentare47.webp',
        '2024-bunkerarena-prezentare48.webp',
        '2024-bunkerarena-prezentare49.webp',
        '2024-bunkerarena-prezentare50.webp',
        '2024-bunkerarena-racing-simulator1.webp',
        '2024-bunkerarena-racing-simulator2.webp',
        '2024-bunkerarena-racing-simulator3.webp',
        '2024-bunkerarena-racing-simulator4.webp',
        '2024-bunkerarena-racing-simulator5.webp',
        '2024-bunkerarena-racing-simulator6.webp',
        '2024-bunkerarena-racing-simulator7.webp',
        '2024-bunkerarena-racing-simulator8.webp',
        '2024-bunkerarena-racing-simulator9.webp',
        '2024-bunkerarena-racing-simulator10.webp',
        '2024-bunkerarena-racing-simulator11.webp',
        '2024-bunkerarena-racing-simulator12.webp',
        '2024-bunkerarena-racing-simulator13.webp',
        '2024-bunkerarena-racing-simulator14.webp',
        '2024-bunkerarena-racing-simulator15.webp',
        '2024-bunkerarena-racing-simulator16.webp',
        '2024-bunkerarena-racing-simulator17.webp',
        '2024-bunkerarena-retro-games1.webp',
        '2024-bunkerarena-retro-games2.webp',
        '2024-bunkerarena-retro-games3.webp',
        '2024-bunkerarena-retro-games4.webp',
        '2024-bunkerarena-retro-games5.webp',
        '2024-bunkerarena-retro-games6.webp',
        '2024-bunkerarena-retro-games7.webp',
        '2024-bunkerarena-retro-games8.webp',
        '2024-bunkerarena-retro-games9.webp',
        '2024-bunkerarena-virtual-reality1.webp',
        '2024-bunkerarena-virtual-reality2.webp',
        '2024-bunkerarena-virtual-reality3.webp',
        '2024-bunkerarena-virtual-reality4.webp',
        '2024-bunkerarena-virtual-reality5.webp',
        '2024-bunkerarena-virtual-reality6.webp',
        '2024-bunkerarena-virtual-reality7.webp',
        '2024-bunkerarena-virtual-reality8.webp',
        '2024-bunkerarena-virtual-reality9.webp',
        '2024-bunkerarena-virtual-reality10.webp',
        '2024-bunkerarena-virtual-reality11.webp',
        '2024-bunkerarena-virtual-reality12.webp',
        '2024-bunkerarena-virtual-reality13.webp',
        '2024-bunkerarena-virtual-reality14.webp',
        '2024-bunkerarena-virtual-reality15.webp',
        '2024-bunkerarena-virtual-reality16.webp',
        '2024-bunkerarena-virtual-reality17.webp',
        '2024-bunkerarena-virtual-reality18.webp',
        '2024-bunkerarena-virtual-reality19.webp',
        '2024-bunkerarena-virtual-reality20.webp',
        'birthday-party.webp',
        'party_s_18.webp',
        'party_s_1.webp',
        'party_s_2.webp',
        'party_s_3.webp',
        'party_s_4.webp',
        'party_s_5.webp',
        'party_s_6.webp',
        'party_s_7.webp',
        'party_s_8.webp',
        'party_s_9.webp',
        'party_s_10.webp',
        'party_s_11.webp',
        'party_s_12.webp',
        'party_s_13.webp',
        'party_s_14.webp',
        'party_s_15.webp',
        'party_s_16.webp',
        'party_s_17.webp',
      ],
    };
  },
  computed: {
    photos() {
      switch (this.selectedSection) {
        default:
        case 'all':
          return this.initialPhotos.filter((item) => item.indexOf('prezentare') >= 0
            || item.indexOf('party') >= 0);
        case 'laser-tag':
        case 'karaoke':
        case 'laser-maze':
        case 'virtual-reality':
        case 'retro-games':
        case 'racing':
          return this.initialPhotos.filter((item) => item.indexOf(this.selectedSection) >= 0);
        case 'party':
          return this.initialPhotos.filter((item) => item.indexOf(this.selectedSection) >= 0);
      }
    },
  },
};
</script>

<style scoped>
  @import '~viewerjs/dist/viewer.css';

  .photo {
    cursor: pointer;
  }

  .btn-link {
    color: white;
  }

  .photo-section:hover {
    cursor: pointer;
    box-shadow: 0 0 10px 3px #1cbdef, 0 0 4px 2px #1cbdef;
  }

  .nav-link {
    color: white;
  }
</style>

<style lang="scss">
  .viewer-navbar {
    background-color: rgba(0, 0, 0, .8);
  }

   .viewer-backdrop {
     background-color: rgba(0, 0, 0, .85);
   }

  .viewer-reset,
  .viewer-play {
    display: none;
  }

  .viewer-toolbar > ul {
    transform: scale(1.5);
  }

  .viewer-close {
    background-color: rgba(28, 189, 239, 1);

    &:hover {
      background-color: rgba(28, 189, 239, .7);
    }
  }

  .viewer-toolbar > ul > li::before {
    margin: 2px;
    background-color: rgba(28, 189, 239, 1);
    border-radius: 10px;
  }
</style>
